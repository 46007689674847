import React from "react";
import TopNav from "./TopNav";
import AsideNavBar from "./AsideNavBar";
import PageContent from "./PageContent";
import Footer from "./Footer";

const Dashboard = () => {
  return (
    <>
      <div className="wrapper">
        <TopNav />
        <AsideNavBar />
        <PageContent />
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
