import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function Dependencies() {
  const navigate = useNavigate();

  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      statename: formvalue.statename,
      statezone: formvalue.statezone,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post(
      "http://localhost/coxidoc/api/appointment.php",
      formData
    );

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-sm-6">
                <h1>Add your Details here</h1>
                <span>{message}</span>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">Dependencies</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-sm-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 class="card-title">Add State here!</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="State Name">State Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="statename"
                          value={formvalue.statename}
                          onChange={handleInput}
                        />
                      </div>

                      <div className="form-group">
                        <label for="Zone">Zone</label>
                        <select
                          required
                          name="statezone"
                          className="form-control"
                          value={formvalue.statezone}
                          onChange={handleInput}
                        >
                          <option value="">Select State Zone</option>
                          <option value="East">East</option>
                          <option value="West">West</option>
                          <option value="North">North</option>
                          <option value="South">South</option>
                        </select>
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-primary">
                        Add State
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-6 col-sm-6">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 class="card-title">Add HeadQuarter here!</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Zone">Select your State</label>
                        <select
                          required
                          name="statename"
                          className="form-control"
                          value={formvalue.statename}
                          onChange={handleInput}
                        >
                          <option value="">Select your State</option>
                          <option value="State1">State1</option>
                          <option value="State3">State3</option>
                          <option value="State4">State4</option>
                        </select>
                      </div>
                      <div className="form-group">
                        <label for="Headquarter Name">Headquarter Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="headquartername"
                          value={formvalue.headquartername}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-warning">
                        Add HeadQuarter
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-md-3 col-sm-6">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 class="card-title">Add Working Type</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">Working Type </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="workingtype"
                          value={formvalue.workingtype}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Working Type
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 class="card-title">Add Division Details</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">Division Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="divisionname"
                          value={formvalue.divisionname}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Division
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 class="card-title">Add User Designation</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">Designmation Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="divisionname"
                          value={formvalue.divisionname}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Designation
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="card card-info">
                  <div className="card-header">
                    <h3 class="card-title">Doctor Degree Details</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">Doctor Degree Name</label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="drDegree"
                          value={formvalue.drDegree}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Doctor Degree
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 class="card-title">Add Dr. Designation</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">
                          Doctor Designation{" "}
                        </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="drDesignation"
                          value={formvalue.drDesignation}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-secondary">
                        Add Dr. Designation
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="col-md-3 col-sm-6">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 class="card-title">Add Dr. Speciality</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label for="Headquarter Name">Dr. Speciality </label>
                        <input
                          required
                          type="text"
                          className="form-control"
                          name="drSpeciality"
                          value={formvalue.drSpeciality}
                          onChange={handleInput}
                        />
                      </div>
                    </div>
                    <div className="card-footer">
                      <button type="submit" class="btn btn-secondary">
                        Add Dr. Speciality
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Dependencies;
