import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import ErrorPage from "../../ErrorPage/ErrorPage";

const AdminUser = () => {
  return (
    <>
      <div className="row mt-3">
        <div class="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Name</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
        <div class="form-group col-md-2 col-sm-6">
          <label for="Headquater Name">Email Id</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
        <div class="form-group col-md-2 col-sm-6">
          <label for="Headquater Name">User Name</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Headquater Name">Password</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Department</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
      </div>
    </>
  );
};

const StaffUser = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Name</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Email Id</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">User Name</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Password</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Department</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>
      </div>
    </>
  );
};

const FieldStaff = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">User Name</label>
          <input
            required
            type="text"
            className="form-control"
            name="userName"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Status">Designation</label>
          <select
            required
            name="userDesignation"
            className="form-control"
            value=""
          >
            <option value="">Select Designation</option>
            <option value="Des01">Des01</option>
            <option value="Des02">Des02</option>
            <option value="Des03">Des03</option>
          </select>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Status">Reporting To</label>
          <select required name="userRpto" className="form-control" value="">
            <option value="">Choose Manager</option>
            <option value="Des01">Manager01</option>
            <option value="Des02">Manager02</option>
            <option value="Des03">Manager03</option>
          </select>
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Status">State</label>
          <select
            required
            name="userDivision"
            className="form-control"
            value=""
          >
            <option value="">SelectState</option>
            <option value="Des01">State01</option>
            <option value="Des02">State02</option>
            <option value="Des03">State03</option>
          </select>
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Status">Headquarter</label>
          <select
            required
            name="userHeadquarter"
            className="form-control"
            value=""
          >
            <option value="">Select Headquarter</option>
            <option value="Des01">Headquarter 01</option>
            <option value="Des02">Headquarter 01</option>
            <option value="Des03">Headquarter 01</option>
          </select>
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Mobile</label>
          <input
            required
            type="text"
            className="form-control"
            name="userMobile"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Email Id</label>
          <input
            required
            type="text"
            className="form-control"
            name="userEmail"
            value=""
          />
        </div>

        <div className="form-group col-md-3 col-sm-6">
          <label for="Headquater Name">Password</label>
          <input
            required
            type="text"
            className="form-control"
            name="userPassword"
            value=""
          />
        </div>
      </div>
    </>
  );
};

function Users() {
  const navigate = useNavigate();

  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
    userBand: "",
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      statename: formvalue.statename,
      statezone: formvalue.statezone,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post(
      "http://localhost/coxidoc/api/appointment.php",
      formData
    );

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  };

  // Select Value data change
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-sm-6">
                <h1>Add User here!</h1>
                <span>{message}</span>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">User</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div class="card card-info">
                  <div class="card-header">
                    <h3 class="card-title">User Details</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="card-body">
                      <div className="row">
                        <div className="col-md-4 col-sm-6">
                          <div class="form-group">
                            <label for="Status" className="primary">
                              User Band
                            </label>
                            <select
                              required
                              name="userBand"
                              className="form-control field-secondary"
                              value={selectedValue.userBand}
                              onChange={handleSelectChange}
                            >
                              <option value="">Select Band</option>
                              <option value="Field Staff">Field Staff</option>
                              <option value="Office Staff">Office Staff</option>
                              <option value="Admin">Admin</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      {/* Short form of if-else using the ternary operator */}
                      {selectedValue === "Field Staff" ? (
                        <FieldStaff />
                      ) : selectedValue === "Office Staff" ? (
                        <StaffUser />
                      ) : selectedValue === "Admin" ? (
                        <AdminUser />
                      ) : (
                        <div className="mt-3">
                          <p className="text-danger">
                            Select your Band First for adding new User
                          </p>
                        </div>
                      )}
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add User
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section class="content mt-3">
          <div class="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12" style={{ textAlign: "center" }}>
                <img
                  src="../dist/img/connection_break.png"
                  style={{ margin: "auto", width: "50%" }}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

export default Users;
