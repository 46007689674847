import React from "react";
import { NavLink } from "react-router-dom";

const ErrorPage = () => {
  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12" style={{ textAlign: "center" }}>
              <img
                src="../dist/img/connection_break.png"
                className="errorfound"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
