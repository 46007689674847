import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  AdminDay,
  Cme,
  Conference,
  Transit,
  Meeting,
  Working,
} from "../Master/WorkingType/WorkingType";

function DcrPlan() {
  const navigate = useNavigate();

  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
    userBand: "",
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      statename: formvalue.statename,
      statezone: formvalue.statezone,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post(
      "http://localhost/coxidoc/api/appointment.php",
      formData
    );

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  };

  // Select Value data change
  const [selectedValue, setSelectedValue] = useState("");

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
  };

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-sm-6">
                <h1>Day Plan</h1>
                <span>{message}</span>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">Day Plan</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div class="card card-primary">
                  <div class="card-header">
                    <h3 class="card-title">Day Plan Details</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="card-body">
                      <div className="row">
                        <div className="form-group col-md-3 col-sm-6">
                          <label for="Date">Date</label>
                          <input
                            required
                            type="date"
                            className="form-control"
                            name="dcr_date"
                            value={selectedValue.dcr_date}
                            onChange={handleSelectChange}
                          />
                        </div>
                        <div className="form-group col-md-3 col-sm-6">
                          <label for="Status">Working Type</label>
                          <select
                            required
                            name="userBand"
                            className="form-control"
                            value={selectedValue.userBand}
                            onChange={handleSelectChange}
                          >
                            <option value="">Select Working Type</option>
                            <option value="Working">Working</option>
                            <option value="Admin Day">Admin Day</option>
                            <option value="CME">CME</option>
                            <option value="Conference">Conference</option>
                            <option value="Transit">Transit</option>
                            <option value="Meeting">Meeting</option>

                            {/* <option value="Field Staff">Field Staff</option>
                              <option value="Office Staff">Office Staff</option>
                              <option value="Admin">Admin</option> */}
                          </select>
                        </div>
                      </div>

                      {/* Short form of if-else using the ternary operator */}
                      {selectedValue === "Working" ? (
                        <Working />
                      ) : selectedValue === "Admin Day" ? (
                        <AdminDay />
                      ) : selectedValue === "CME" ? (
                        <Cme />
                      ) : selectedValue === "Conference" ? (
                        <Conference />
                      ) : selectedValue === "Meeting" ? (
                        <Meeting />
                      ) : selectedValue === "Transit" ? (
                        <Transit />
                      ) : (
                        <div>
                          <p
                            className="text-danger"
                            style={{ marginBottom: "0px" }}
                          >
                            Select your Working type First for Day Plan
                          </p>
                        </div>
                      )}
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Day Plan
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DcrPlan;
