import React from "react";

function AdminDay() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-2 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>

        <div className="form-group col-md-2 col-sm-6">
          <label for="End time">End Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="end_time"
            value=""
          />
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Area">Area</label>
          <input
            required
            type="text"
            className="form-control"
            name="area"
            value=""
          />
        </div>
        <div className="form-group col-md-5 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}

function Cme() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-1 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>

        <div className="form-group col-md-1 col-sm-6">
          <label for="End time">End Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="end_time"
            value=""
          />
        </div>

        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">Working with</label>
          <select required name="working_with" className="form-control">
            <option value="">Select Working with</option>
            <option value="Staff 01">Staff 01</option>
            <option value="Staff 02">Staff 02</option>
            <option value="Staff 03">Staff 03</option>
          </select>
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Area">Area</label>
          <select required name="area" className="form-control">
            <option value="">Select Area</option>
            <option value="Area 01">Area 01</option>
            <option value="Area 02">Area 02</option>
            <option value="Area 03">Area 03</option>
          </select>
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Institute">Institute</label>
          <select required name="Institute" className="form-control">
            <option value="">Select Institute</option>
            <option value="Institute 01">Institute 01</option>
            <option value="Institute 02">Institute 02</option>
            <option value="Institute 03">Institute 03</option>
          </select>
        </div>

        <div className="form-group col-md-4 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}

function Conference() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-1 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>

        <div className="form-group col-md-1 col-sm-6">
          <div class="form-group">
            <label for="End time">End Time</label>
            <input
              required
              type="time"
              className="form-control"
              name="end_time"
              value=""
            />
          </div>
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">Working with</label>
          <select required name="working_with" className="form-control">
            <option value="">Select Working with</option>
            <option value="Staff 01">Staff 01</option>
            <option value="Staff 02">Staff 02</option>
            <option value="Staff 03">Staff 03</option>
          </select>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Area">Area</label>
          <select required name="area" className="form-control">
            <option value="">Select Area</option>
            <option value="Area 01">Area 01</option>
            <option value="Area 02">Area 02</option>
            <option value="Area 03">Area 03</option>
          </select>
        </div>

        <div className="form-group col-md-5 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}

function Transit() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-1 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>

        <div className="form-group col-md-1 col-sm-6">
          <label for="End time">End Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="end_time"
            value=""
          />
        </div>

        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">From</label>
          <select required name="from" className="form-control">
            <option value="">Select From</option>
            <option value="From 01">From 01</option>
            <option value="From 02">From 02</option>
            <option value="From 03">From 03</option>
          </select>
        </div>

        <div className="form-group col-md-2 col-sm-6">
          <label for="To">To</label>
          <select required name="To" className="form-control">
            <option value="">Select To</option>
            <option value="To 01">To 01</option>
            <option value="To 02">To 02</option>
            <option value="To 03">To 03</option>
          </select>
        </div>

        <div className="form-group col-md-6 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}

function Meeting() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-1 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>
        <div className="form-group col-md-1 col-sm-6">
          <label for="End time">End Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="end_time"
            value=""
          />
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">Working with</label>
          <select required name="working_with" className="form-control">
            <option value="">Select Working with</option>
            <option value="Staff 01">Staff 01</option>
            <option value="Staff 02">Staff 02</option>
            <option value="Staff 03">Staff 03</option>
          </select>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Area">Area</label>
          <select required name="area" className="form-control">
            <option value="">Select Area</option>
            <option value="Area 01">Area 01</option>
            <option value="Area 02">Area 02</option>
            <option value="Area 03">Area 03</option>
          </select>
        </div>

        <div className="form-group col-md-5 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}
function Working() {
  return (
    <div>
      <div className="row m-mt-3">
        <div className="form-group col-md-1 col-sm-6">
          <label for="Start time">Start Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="start_time"
            value=""
          />
        </div>

        <div className="form-group col-md-1 col-sm-6">
          <label for="End time">End Time</label>
          <input
            required
            type="time"
            className="form-control"
            name="end_time"
            value=""
          />
        </div>

        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">Visited</label>
          <select required name="working_with" className="form-control">
            <option value="">Select One</option>
            <option value="Staff 01">Doctor</option>
            <option value="Staff 02">Chemist</option>
            <option value="Staff 03">Hospital</option>
            <option value="Staff 03">Institute</option>
          </select>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Area">Area</label>
          <select required name="area" className="form-control">
            <option value="">Select Area</option>
            <option value="Area 01">Area 01</option>
            <option value="Area 02">Area 02</option>
            <option value="Area 03">Area 03</option>
          </select>
        </div>
        <div className="form-group col-md-2 col-sm-6">
          <label for="Working with">Name</label>
          <select required name="working_with" className="form-control">
            <option value="">Select One</option>
            <option value="Staff 01">Name 01</option>
            <option value="Staff 02">Name 02</option>
            <option value="Staff 03">Name 03</option>
            <option value="Staff 03">Name 04</option>
          </select>
        </div>
        <div className="form-group col-md-3 col-sm-6">
          <label for="Working with">Working with</label>
          <select required name="working_with" className="form-control">
            <option value="">Select Working with</option>
            <option value="Staff 01">Staff 01</option>
            <option value="Staff 02">Staff 02</option>
            <option value="Staff 03">Staff 03</option>
          </select>
        </div>

        <div className="form-group col-md-12 col-sm-6">
          <label for="Remark">Remark</label>
          <input
            required
            type="text"
            className="form-control"
            name="remark"
            value=""
          />
        </div>
      </div>
    </div>
  );
}
export { AdminDay, Cme, Conference, Transit, Meeting, Working };
