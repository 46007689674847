import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function Doctors() {
  const navigate = useNavigate();

  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      statename: formvalue.statename,
      statezone: formvalue.statezone,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post(
      "http://localhost/coxidoc/api/appointment.php",
      formData
    );

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-sm-6">
                <h1>Add Doctors here!</h1>
                <span>{message}</span>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">Doctors</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div class="card card-secondary">
                  <div class="card-header">
                    <h3 class="card-title">Add doctors details here</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="card-body">
                      <div className="row">
                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Headquater Name">Dr. Name</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drName"
                            value={formvalue.drName}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Status">Degree</label>
                          <select
                            required
                            name="userDesignation"
                            className="form-control"
                            value={formvalue.userDesignation}
                            onChange={handleInput}
                          >
                            <option value="">Select Degree</option>
                            <option value="Des01">Degree01</option>
                            <option value="Des02">Degree02</option>
                            <option value="Des03">Degree03</option>
                          </select>
                        </div>

                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Status">Speciality</label>
                          <select
                            required
                            name="userDesignation"
                            className="form-control"
                            value={formvalue.userDesignation}
                            onChange={handleInput}
                          >
                            <option value="">Select Speciality</option>
                            <option value="Des01">Speciality01</option>
                            <option value="Des02">Speciality02</option>
                            <option value="Des03">Speciality003</option>
                          </select>
                        </div>

                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Status">Designation</label>
                          <select
                            required
                            name="userDesignation"
                            className="form-control"
                            value={formvalue.userDesignation}
                            onChange={handleInput}
                          >
                            <option value="">Select Designation</option>
                            <option value="Des01">Des01</option>
                            <option value="Des02">Des02</option>
                            <option value="Des03">Des03</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mt-3 reg-add">
                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Headquater Name">
                            Residential Address
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Status">Reg State</label>
                          <select
                            required
                            name="userRpto"
                            className="form-control"
                            value={formvalue.userRpto}
                            onChange={handleInput}
                          >
                            <option value="">Choose State</option>
                            <option value="Des01">State01</option>
                            <option value="Des02">State02</option>
                            <option value="Des03">State03</option>
                          </select>
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Status">Reg City</label>
                          <select
                            required
                            name="userDesignation"
                            className="form-control"
                            value={formvalue.userDesignation}
                            onChange={handleInput}
                          >
                            <option value="">Select Area</option>
                            <option value="Des01">Area01</option>
                            <option value="Des02">Area02</option>
                            <option value="Des03">Area03</option>
                          </select>
                        </div>

                        <div class="form-group col-md-1 col-sm-6">
                          <label for="Headquater Name">Pin</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Mobile</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Fax</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="row mt-3 clinic-add">
                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Headquater Name">Clinic Address</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Status">Clinic State</label>
                          <select
                            required
                            name="userRpto"
                            className="form-control"
                            value={formvalue.userRpto}
                            onChange={handleInput}
                          >
                            <option value="">Choose State</option>
                            <option value="Des01">State01</option>
                            <option value="Des02">State02</option>
                            <option value="Des03">State03</option>
                          </select>
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Status">City</label>
                          <select
                            required
                            name="userDesignation"
                            className="form-control"
                            value={formvalue.userDesignation}
                            onChange={handleInput}
                          >
                            <option value="">Select Area</option>
                            <option value="Des01">Area01</option>
                            <option value="Des02">Area02</option>
                            <option value="Des03">Area03</option>
                          </select>
                        </div>

                        <div class="form-group col-md-1 col-sm-6">
                          <label for="Headquater Name">Pin</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Mobile</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Fax</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">DOB</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Gender</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Marital Status</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          {" "}
                          <label for="Headquater Name">Marriage Ann</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Religion</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Visit Freq - PM</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div class="form-group col-md-3 col-sm-6">
                          <label for="Headquater Name">
                            Prescribed Products
                          </label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>
                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Hospital Name</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Hospital Area</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-2 col-sm-6">
                          <label for="Headquater Name">Email Id</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-3 col-sm-6">
                          {" "}
                          <label for="Headquater Name">Website</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="drQualification"
                            value={formvalue.drQualification}
                            onChange={handleInput}
                          />
                        </div>
                      </div>
                    </div>

                    <div class="card-footer">
                      <button type="submit" class="btn btn-secondary">
                        Add Doctor
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Doctors;
