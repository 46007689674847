import React from "react";
import { Link, NavLink } from "react-router-dom";

const AsideNavBar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src="dist/img/user2-160x160.jpg"
              className="img-circle elevation-2"
              alt=""
            />
          </div>
          <div className="info">
            <Link to="#" className="d-block">
              Kutti Sharma
            </Link>
          </div>
        </div>

        <div className="form-inline">
          <div className="input-group" data-widget="sidebar-search">
            <input
              className="form-control form-control-sidebar"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <div className="input-group-append">
              <button className="btn btn-sidebar">
                <i className="fas fa-search fa-fw"></i>
              </button>
            </div>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink exact to="/Dashboard" className="nav-link">
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>

            <li className="nav-item has-treeview">
              <Link
                to="#"
                className="nav-link"
                // onClick={(e) => {
                //   e.preventDefault();
                // }}
              >
                <i className="nav-icon fas fa-tasks"></i>
                <p>
                  Masters
                  <i className="right fas fa-angle-left"></i>
                  <span className="badge badge-warning right">New</span>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink exact to="/Dependencies" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Dependencies</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Users" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Users</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Areas" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Areas</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Doctors" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Doctors</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Hospitals" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Hospitals/Institutions</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Chemists" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Chemists</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Stockists" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Stockists</p>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav-item has-treeview">
              <Link
                to="#"
                // onClick={(e) => {
                //   e.preventDefault();
                // }}
                className="nav-link"
              >
                <i className="nav-icon fas fa-database"></i>
                <p>
                  All Data
                  <i className="right fas fa-angle-left"></i>
                  <span className="badge badge-info right">Edits</span>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink exact to="/allDepencies" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>All Dependencies</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/AllUsersData" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>All Users</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/AllDoctorsData" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>All Doctors</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/AllHospitalsData" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>All Hospitals</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/All Clinics Data" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>All Clinics</p>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav-item has-treeview">
              <Link
                to="#"
                // onClick={(e) => {
                //   e.preventDefault();
                // }}
                className="nav-link"
              >
                <i className="nav-icon fa fa-map-signs"></i>
                <p>
                  Mapping
                  <i className="right fas fa-angle-left"></i>
                  <span className="badge badge-danger right">New</span>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink exact to="/CreateFieldStaff" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Create Field Staff</p>
                  </NavLink>
                </li>
              </ul>
            </li>

            <li className="nav-item has-treeview">
              <Link
                to="/"
                // onClick={(e) => {
                //   e.preventDefault();
                // }}
                className="nav-link"
              >
                <i className="nav-icon fas fa-book"></i>
                <p>
                  Transaction
                  <i className="right fas fa-angle-left"></i>
                  <span className="badge badge-success right">Entries</span>
                </p>
              </Link>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <NavLink exact to="/DayPlan" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Day Plan</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/DcrReport" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>DCR Entry</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Mail" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Mail</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/TP" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>TP</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Sales" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Sales</p>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact to="/Reports" className="nav-link">
                    <i className="far fa-circle nav-icon"></i>
                    <p>Reports</p>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default AsideNavBar;
