import React from "react";
import { Routes, Route } from "react-router-dom";

import ErrorPage from "../ErrorPage/ErrorPage";
import ContentDashboard from "./ContentDashboard";

import Dependencies from "../Master/Dependencies/Dependencies";

import Users from "../Master/Users/Users";
import Areas from "../Master/Areas/Areas";
import Doctors from "../Master/Doctors/Doctors";
import Hospitals from "../Master/Hospitals/Hospitals";
import Chemists from "../Master/Chemists/Chemists";
import Stockists from "../Master/Stockists/Stockists";

import DcrReport from "../Reports/DcrReport";
import DayPlan from "../Reports/DayPlan";
// import DashboardAllData from "../Master/DashboardAllData";
// import NewAppoinment from "../Master/NewAppoinment";
// import AppoinmentData from "../Master/AppoinmentData";

// import Newopd from "../OpdData/Newopd";
// import OpdData from "../OpdData/OpdData";
// import Editopd from "../OpdData/Editopd";

// import PatientData from "../PatientData/PatientData";
// import NewProcedure from "../ProcedureData/NewProcedure";
// import Invoice from "../Invoice/Invoice";
// import EditAppointment from "../Master/EditAppointment";

// Page Content

const PageContent = () => {
  return (
    <>
      <Routes>
        <Route exact path="/Dashboard" element={<ContentDashboard />} />

        {/* <Route exact path="/MasterDashboard" element={<MasterDashboard />} /> */}
        <Route exact path="/Dependencies" element={<Dependencies />} />
        <Route exact path="/Users" element={<Users />} />
        <Route exact path="/Areas" element={<Areas />} />
        <Route exact path="/Doctors" element={<Doctors />} />
        <Route exact path="/Hospitals" element={<Hospitals />} />
        <Route exact path="/Chemists" element={<Chemists />} />
        <Route exact path="/Stockists" element={<Stockists />} />

        <Route exact path="/DcrReport" element={<DcrReport />} />
        <Route exact path="/DayPlan" element={<DayPlan />} />

        {/* <Route exact path='/DashboardAllData' element={<DashboardAllData />} /> */}

        {/* <Route exact path="/newappoinment" element={<NewAppoinment />} />
        <Route exact path="/appoinmentdata" element={<AppoinmentData />} />
        <Route
          exact
          path="/editappointment/:id"
          element={<EditAppointment />}
        />
        <Route exact path="/newopd" element={<Newopd />} />
        <Route exact path="/opddata" element={<OpdData />} />
        <Route exact path="/editopd/:id" element={<Editopd />} />
        <Route exact path="/newprocedure" element={<NewProcedure />} />
        <Route exact path="/patientdata" element={<PatientData />} /> */}
        {/* <Route exact path='/invoice' element={<Invoice />} /> */}

        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default PageContent;
