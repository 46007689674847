import axios from "axios";
import React, { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";

function Areas() {
  const navigate = useNavigate();

  const [formvalue, setFormvalue] = useState({
    pname: "",
    pmob: "",
    padd: "",
    padate: "",
    patime: "",
    padoc: "",
    pmsg: "",
    pcat: "",
    pstatus: "",
  });

  const [message, setMessage] = useState("");

  const handleInput = (e) => {
    setFormvalue({ ...formvalue, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(formvalue);
    const formData = {
      statename: formvalue.statename,
      statezone: formvalue.statezone,
      padd: formvalue.padd,
      padate: formvalue.padate,
      patime: formvalue.patime,
      padoc: formvalue.padoc,
      pmsg: formvalue.pmsg,
      pcat: formvalue.pcat,
      pstatus: formvalue.pstatus,
    };
    const res = await axios.post(
      "http://localhost/coxidoc/api/appointment.php",
      formData
    );

    if (res.data.success) {
      setMessage(res.data.success);
      setTimeout(() => {
        navigate("/AppoinmentData");
      }, 1000);
    }
  };
  return (
    <React.Fragment>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mt-2">
              <div className="col-sm-6">
                <h1>Add Area!</h1>
                <span>{message}</span>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <NavLink to="/dashboard">Home</NavLink>
                  </li>
                  <li className="breadcrumb-item active">Area</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div class="card card-info">
                  <div class="card-header">
                    <h3 class="card-title">Area Details</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div class="card-body">
                      <div className="row">
                        <div class="form-group col-md-4 col-sm-6">
                          <label for="Headquater Name">Area Name</label>
                          <input
                            required
                            type="text"
                            className="form-control"
                            name="userName"
                            value={formvalue.userName}
                            onChange={handleInput}
                          />
                        </div>

                        <div class="form-group col-md-4 col-sm-6">
                          <label for="Status">Select State</label>
                          <select
                            required
                            name="userBand"
                            className="form-control"
                            value={formvalue.userBand}
                            onChange={handleInput}
                          >
                            <option value="">Select State</option>
                            <option value="Band 01">State01</option>
                            <option value="Admin">State02</option>
                          </select>
                        </div>
                        <div class="form-group col-md-4 col-sm-6">
                          <label for="Status">HeadQuarter</label>
                          <select
                            required
                            name="userBand"
                            className="form-control"
                            value={formvalue.userBand}
                            onChange={handleInput}
                          >
                            <option value="">Select HeadQuarter</option>
                            <option value="Band 01">HeadQuarter01</option>
                            <option value="Admin">HeadQuarter02</option>
                          </select>
                        </div>

                        {/* <div className="col-2">
                          <div class="form-group">
                            <label for="Status">Area Type</label>
                            <select
                              required
                              name="userDesignation"
                              className="form-control"
                              value={formvalue.userDesignation}
                              onChange={handleInput}
                            >
                              <option value="">Select Type</option>
                              <option value="Des01">New Area</option>
                              <option value="Des02">Modified Area</option>
                            </select>
                          </div>
                        </div> */}
                        {/* <div className="col-3">
                          <div class="form-group">
                            <label for="Status">Status</label>
                            <select
                              required
                              name="statestatus"
                              className="form-control"
                              value={formvalue.statestatus}
                              onChange={handleInput}
                            >
                              <option value="">Status</option>
                              <option value="Active">Active</option>
                              <option value="In Active">In Active</option>
                            </select>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div class="card-footer">
                      <button type="submit" class="btn btn-info">
                        Add Area
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Areas;
